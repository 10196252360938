@import url("https://fonts.cdnfonts.com/css/satoshi");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply relative z-1 bg-whiten font-satoshi text-base font-normal text-body;
  }
}

/* App.css */
.diagram-component {
  width: 100%;
}
  
@layer components {
}