/* TABLE */
.datatable-table {
	max-width: 100%;
	width: 100%;
	min-height: auto !important;
}

.datatable-table > tbody > tr > td,
.datatable-table > tbody > tr > th,
.datatable-table > tfoot > tr > td,
.datatable-table > tfoot > tr > th,
.datatable-table > thead > tr > td,
.datatable-table > thead > tr > th {
	vertical-align: top;
	padding: 20px;
	word-wrap: break-word;
}

.datatable-table > thead > tr > th {
	vertical-align: bottom;
	text-align: left;
	border-bottom: 1px solid #d9d9d9;
	padding: 20px;
}

.datatable-table > tfoot > tr > th {
	vertical-align: bottom;
	text-align: left;
	border-top: 1px solid #d9d9d9;
}

.datatable-table th {
	vertical-align: bottom;
	text-align: left;
}

.datatable-table th a {
	text-decoration: none;
	color: inherit;
}

.datatable-one tbody tr td:first-child {
	color: #3C50E0;
	font-weight: 500;

}

.datatable-table tbody tr:hover {
	background: #F8FBFF;
}


.datatable-sorter, .datatable-filter {
	display: inline-block;
	height: 100%;
	position: relative;
	width: 100%;
}

.datatable-sorter::before,
.datatable-sorter::after {
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	right: 4px;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	opacity: 0.2;
}


.datatable-sorter::before {
	border-top: 4px solid #000;
	bottom: 0px;
}

.datatable-sorter::after {
	border-bottom: 4px solid #000;
	border-top: 4px solid transparent;
	top: 0px;
}

.datatable-ascending .datatable-sorter::after,
.datatable-descending .datatable-sorter::before,
.datatable-ascending .datatable-filter::after,
.datatable-descending .datatable-filter::before {
	opacity: 0.6;
}

.datatable-filter::before {
    content: "";
    position: absolute;
    right: 4px;
    opacity: 0.2;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
	border-radius: 50%;
    border-top: 10px solid #000;
    top: 25%;
}

.datatable-filter-active .datatable-filter::before {
    opacity: 0.6;
}

.datatable-empty {
	text-align: center;
}

.datatable-top::after, .datatable-bottom::after {
	clear: both;
	content: " ";
	display: table;
}

table.datatable-table:focus tr.datatable-cursor > td:first-child {
	border-left: 3px blue solid;
}

table.datatable-table:focus {
	outline: solid 1px black;
    outline-offset: -1px;
}