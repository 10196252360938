.data-table-common {
    @apply rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark pb-4 overflow-x-auto relative;
  
    .datatable-wrapper.no-footer .datatable-container {
      @apply border-none;
    }
  
    .datatable-info {
      @apply !m-0 font-medium;
    }
  
    .datatable-pagination {
      @apply flex w-full justify-between items-center gap-4 max-w-7xl mx-auto px-8 pt-5 border-t border-stroke;
  
      .page-buttons {
        display: flex;
  
        > button {
          @apply p-1 px-2 rounded-md cursor-pointer hover:text-white hover:bg-primary flex items-center justify-center disabled:cursor-default disabled:hover:bg-transparent disabled:text-current;
          &:not(:first-child),
          &:not(:last-child) {
            @apply mx-1;
          }
        }
      }
  
      .page-selector {
        @apply w-16 rounded-lg border-[1.5px] border-stroke bg-transparent py-2 px-2 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary;
      }
    }
  
    .datatable-filter-header {
      @apply flex w-full justify-between items-center gap-4 max-w-7xl mx-auto px-8 py-4 border-b border-stroke;
    }
  }
  
  .datatable-table {
    @apply w-full border-collapse break-words table-auto overflow-hidden px-4 md:overflow-auto md:table-fixed md:px-8 min-h-80;
    > thead {
      > tr:first-child > th {
        @apply font-medium border-transparent pr-2.5 pt-9 pb-2.5;
      }
    }
    > thead {
      > tr {
        &:last-child {
          > th {
            @apply !border-b border-stroke dark:border-strokedark pb-6;
          }
        }
        > th {
          &:first-child {
            @apply pl-8;
          }
          &:last-child {
            @apply pr-8;
          }
        }
      }
    }
    > tbody {
      > tr {
        @apply hover:bg-primary hover:bg-opacity-5;
  
        > td {
          @apply py-5 border-b border-stroke dark:border-strokedark font-medium;
          &:first-child {
            @apply pl-8;
          }
          &:last-child {
            @apply pr-8;
          }
        }
      }
    }
  }
  
  .data-table-primary-left-column .datatable-table > tbody > tr > td:first-child {
    @apply text-primary dark:text-white;
  }