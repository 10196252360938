.button {
    @apply inline-flex items-center gap-2.5 cursor-pointer disabled:pointer-events-none;
    &.primary-outline {
      @apply border-y border-stroke font-medium text-black hover:border-primary hover:bg-primary hover:text-white dark:border-strokedark dark:text-white dark:hover:border-primary py-1 px-2 sm:py-3 sm:px-6;
    }
  
    &.primary {
      @apply justify-center rounded-md bg-primary my-2 ml-2 px-4 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10;
    }
  
    &.icon-only {
      @apply relative inline-block py-1 px-1 sm:py-2 sm:px-2 overflow-hidden hover:overflow-visible;
    }
  
    .tooltip {
      @apply absolute top-full left-1/2 z-20 mt-3 -translate-x-1/2 whitespace-nowrap rounded bg-black py-1.5 px-4.5 text-sm font-medium text-white opacity-0;
      > span {
        @apply absolute top-[-3px] left-1/2 -z-10 h-2 w-2 -translate-x-1/2 rotate-45 rounded-sm bg-black;
      }
    }
  }
  .action-buttons-group {
    @apply flex flex-wrap items-center;
    .button {
      &:first-child {
        @apply border-l rounded-l-md;
      }
      &:last-child {
        @apply border-r rounded-r-md;
      }
    }
  }